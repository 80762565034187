import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mainclass'
})
export class MainclassPipe implements PipeTransform {
  transform = (uid: string): string => {
    return `/api/v1/class/main/${uid}/img`;
  }
}
