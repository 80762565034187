import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maincourse'
})
export class MaincoursePipe implements PipeTransform {
  transform = (uid: string): string => {
    return `/api/v1/course/main/img/${uid}`;
  }
}
