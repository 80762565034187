import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'student'
})
export class StudentPipe implements PipeTransform {

  transform = (studentId: string): string => {
    return `/api/v1/student/real/${studentId}/img`;
  }

}

