import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  isCollapsed = true;

  path = '';
  mainPath = '';
  subPath = '';

  levelPermissionFeature: string;

  listContent = [];

  constructor(
    private router: Router,
    private auth: AuthService,
    private msg: NzMessageService,
  ) { }

  ngOnInit(): void {
    this.isCollapsed = (localStorage.getItem('isCollapsed') === 'true');
    this.path = this.router.url;
    this.mainPath = `${this.path.split('/')[1]}`;
    this.subPath = `${this.path.split('/')[1]}/${this.path.split('/')[2]}`;

    this.levelPermissionFeature = localStorage.getItem('feature');
  }

  isCollapsedChange = (): void => {
    this.isCollapsed = !this.isCollapsed;
    localStorage.setItem('isCollapsed', this.isCollapsed.toString());
  }

  logout = (): void => {
    this.auth.logout().then((r) => {
      if (r) {
        this.msg.success('Logout Success');
        this.router.navigateByUrl('/auth');
      }
    }).catch((e) => {
      if (e.error === 'Unauthorized') {
        this.msg.success('Logout Success');
        this.router.navigateByUrl('/auth');
      } else {
        this.msg.error('Log out Fail');
      }
    });
  }


}
