import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ng-zorro
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzMessageService } from 'ng-zorro-antd';
import { RecaptchaModule } from 'ng-recaptcha';
import { ExportExcelService } from './services/export-excel.service';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzPopoverModule } from 'ng-zorro-antd/popover';


// pipe
import { AvatarPipe } from './pipes/avatar.pipe';
import { DatePipe } from '@angular/common';
import { MaincoursePipe } from './pipes/maincourse.pipe';
import { SpecialcoursePipe } from './pipes/specialcourse.pipe';
import { StudentPipe } from './pipes/student.pipe';
import { TeacherPipe } from './pipes/teacher.pipe';
import { MainclassPipe } from './pipes/mainclass.pipe';


@NgModule({
  declarations: [
    AvatarPipe,
    MaincoursePipe,
    SpecialcoursePipe,
    StudentPipe,
    TeacherPipe,
    MainclassPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    // ng-zorro
    NzButtonModule,
    NzIconModule,
    NzGridModule,
    NzDropDownModule,
    NzPaginationModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzAvatarModule,
    NzTableModule,
    NzModalModule,
    NzDrawerModule,
    NzDividerModule,
    NzSpinModule,
    NzMenuModule,
    NzListModule,
    NzInputNumberModule,
    NzRadioModule,
    NzCardModule,
    NzProgressModule,
    NzCalendarModule,
    NzTabsModule,
    NzUploadModule,
    NzTimePickerModule,
    NzTagModule,
    NzEmptyModule,
    NzToolTipModule,
    NzCarouselModule,
    NzSwitchModule,
    NzBadgeModule,
    NzPopoverModule,

    RecaptchaModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarPipe,
    DatePipe,
    MaincoursePipe,
    SpecialcoursePipe,
    StudentPipe,
    TeacherPipe,
    MainclassPipe,

    // ng-zorro
    NzButtonModule,
    NzIconModule,
    NzGridModule,
    NzDropDownModule,
    NzPaginationModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzAvatarModule,
    NzTableModule,
    NzModalModule,
    NzDrawerModule,
    NzDividerModule,
    NzSpinModule,
    NzMenuModule,
    NzListModule,
    NzInputNumberModule,
    NzRadioModule,
    NzCardModule,
    NzProgressModule,
    NzCalendarModule,
    NzTabsModule,
    NzUploadModule,
    NzTimePickerModule,
    NzTagModule,
    NzEmptyModule,
    NzToolTipModule,
    NzCarouselModule,
    NzSwitchModule,
    NzBadgeModule,
    NzPopoverModule,

    RecaptchaModule,
  ],
  providers: [
    NzMessageService,
    ExportExcelService,
  ]

})
export class SharedModule { }
