import * as CryptoJS from 'crypto-js';

/**
 *
 * @param x: number
 */
export function numberWithCommas(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

/**
 *
 * @param s: number
 */
export function mathRound(s: number) {
    return Math.round(s);
}

/**
 *
 * @param len: number
 * Cr: https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 */
export function generateSalt(len: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < len; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


/**
 *
 * @param buffer: ArrayBuffer
 */
export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};



export const getDifferenceInDays = (date1: Date, date2: Date): number => {
    const diffInMs = Math.abs(+date2 - +date1);
    return diffInMs / (1000 * 60 * 60 * 24);
};

export const getAge = (birthdate: Date): number => {
    const timeDiff = Math.abs(Date.now() - birthdate.getTime());
    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    return age;
};

export const addDays = (date: Date, days: number): Date => {
    date.setDate(date.getDate() + days);
    return date;
};

export const addMinutes = (date: Date, minutes: number): Date => {
    return new Date(date.getTime() + minutes * 60000);
};

export const hiddenCreditCard = (no: string): string => {
    const text = `************${no.substring(no.length - 4, no.length)}`;
    return text;
};

export const minuteToHours = (minute: number): string => {
    if (minute === 0) {
        return `0`;
    }
    const hours = Math.floor(minute / 60);
    const minutes = Math.floor(minute % 60);
    const text = `${hours} ชั่วโมง ${minutes} นาที`;
    return text;
};

/**
 *
 * @param year: number
 */
export const renderCalendarYear = (selectedYear: number): any => {

    const calendarYear = [];
    let daysOfMonth: number;

    for (let i = 1; i < 13; i++) {

        if (i === 12) {
            daysOfMonth = 31;
        } else {
            daysOfMonth = getDifferenceInDays(new Date(`${i}-1-${selectedYear}`), new Date(`${i + 1}-1-${selectedYear}`));
        }

        const days = daysOfMonth;

        let weekDay = '';
        const listWeek = [];
        const dayOfMonth = [];

        for (let j = 0; j < days; j++) {
            const thisDate = addDays(new Date(`${i}-1-${selectedYear}`), j);
            const thisDay = thisDate.getDay();
            dayOfMonth.push({
                date: thisDate,
                day: thisDay,
            });
            weekDay += thisDay.toString();
        }

        while (weekDay.length > 6) {
            listWeek.push(weekDay.substring(0, 7 - Number(weekDay[0])).length);
            weekDay = weekDay.substring(weekDay.indexOf('6') + 1, weekDay.length);
        }

        if (weekDay.length > 0) {
            listWeek.push(weekDay.length);
        }

        const listWeekDict = [];
        let listWeekDictSub = [];

        let n = 0;
        listWeek.forEach((a) => {
            for (let m = n; m < n + a; m++) {
                listWeekDictSub.push(dayOfMonth[m]);
            }
            n = n + a;
            listWeekDict.push(listWeekDictSub);
            listWeekDictSub = [];
        });

        const calendarMonth = {
            year: selectedYear,
            month: i,
            date: []
        };

        const listDate = [];

        listWeekDict.forEach((b, indexB) => {
            const dictDate = {
                week: null,
                0: null,
                1: null,
                2: null,
                3: null,
                4: null,
                5: null,
                6: null,
            };
            dictDate.week = indexB + 1;
            b.forEach((c) => {
                dictDate[c.day] = c.date;
            });
            listDate.push(dictDate);
        });

        calendarMonth.date = listDate;
        calendarYear.push(calendarMonth);
    }
    return calendarYear;
};

export const checkSpacebarAll = (str: string): boolean => {
    if (str === null || str === '') {
        return false
    } else if (!str.replace(/\s/g, '').length) {
        return true
    }
    return false
};

export const encryptData = (data: any): string => {
    const dataString = JSON.stringify(data);
    // const key = localStorage.getItem('token').substring(0, 32);
    const key = '12345678abdcefghi.12345678abdcef'
    const encrypted = CryptoJS.AES.encrypt(dataString, key);
    return encrypted.toString();
}

export const decryptData = (data: string): string => {
    // const key = localStorage.getItem('token').substring(0, 32);
    const key = '12345678abdcefghi.12345678abdcef'
    const decrypted = CryptoJS.AES.decrypt(data, key);
    return decrypted.toString(CryptoJS.enc.Utf8);
}



