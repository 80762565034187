<!-- <div style="height: 100%;" *ngIf="userNavLinks">

  <nz-layout style="background-color: #f6f7f9;">
    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" class="st-sider">
      <div class="logo">
        <div *ngIf="!isCollapsed">Related Parties Transaction</div>
        <div *ngIf="isCollapsed" style="font-size: 20px;">RPT</div>
      </div>
      <ul nz-menu nzMode="inline" nzTheme="light" [nzInlineCollapsed]="isCollapsed"
        style="height: 100vh; border: none;">
        <nz-divider nzText="User" nzOrientation="left" class="st-title-menu"></nz-divider>

        <div *ngFor="let menu of userNavLinks">
          <li nz-submenu [nzTitle]="isCollapsed ? menu.display : ''" [nzIcon]="menu.icon" *ngIf="menu.sub.length!==0"
            [nzOpen]="mainPath===menu.link">
            <ul>
              <li nz-menu-item *ngFor="let sub of menu.sub" (click)="goPage(sub.link)" [nzSelected]="path===sub.link">
                {{ sub.display }}</li>
            </ul>
          </li>
          <li nz-menu-item *ngIf="menu.sub.length===0" (click)="goPage(menu.link)"
            [nzSelected]="menu.link.split('/')[1]===mainPath">
            <i nz-icon [nzType]="menu.icon" [ngClass]="isCollapsed ? 'st-icon-collap': ''"></i>
            <span *ngIf="!isCollapsed">{{ menu.display }}</span>
          </li>
        </div>

        <div *ngIf="level===99" style="margin-top: 35px;">
          <nz-divider nzText="Super Admin" nzOrientation="left" class="st-title-menu" *ngIf="!isCollapsed"></nz-divider>
          <nz-divider nzText="Super" nzOrientation="left" class="st-title-menu" *ngIf="isCollapsed"></nz-divider>
          <li nz-menu-item *ngFor="let super of superAdminNavLinks" (click)="goPage(super.link)"
            [nzSelected]="path===super.link">
            <i nz-icon [nzType]="super.icon" [ngClass]="isCollapsed ? 'st-icon-collap': ''"></i>
            <span *ngIf="!isCollapsed">{{ super.display }}</span>
          </li>
        </div>
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-header nz-row [ngClass]="isCollapsed ? 'st-header-collap': ''">
        <div nz-col nzSpan="12">
          <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            (click)="isCollapsedChange()"></i>
        </div>
        <div nz-col nzSpan="12" style="text-align: right; padding-right: 25px;">
          <span style=" margin-right: 10px; font-size: 12px; color: rgba(0,0,0,0.35);">Thanittha Jarimophas</span>
          <nz-avatar [nzSize]="30" nzIcon="user" nzSrc="">
          </nz-avatar>
        </div>
      </nz-header>
      <nz-content [ngClass]="isCollapsed ? 'st-content-collap': ''">
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
      <nz-footer>
        2020 INET Digital Outsource (KaaS)
      </nz-footer>
    </nz-layout>
  </nz-layout>

</div> -->

<!-- ------------------------------------------------------------------------------------------------------------------------ -->

<!-- <nz-layout>
  <nz-header style="background-color: #1890ff; height: 65px; padding: 0 20px;">
    <div class="logo" style="position: relative;">
      <img style="position: absolute; top: -12px;"
        [src]="'https://whizkidzenglish.com/wp-content/uploads/2018/04/LogoWK120px.png'"
        [ngStyle]="{ width: '150px' }" />

    </div>
  </nz-header>
  <nz-layout>
    <nz-sider nzWidth="200px" nzTheme="light" style="padding-top: 10px;">
      <ul nz-menu nzMode="inline" class="sider-menu">
        <li nz-submenu nzOpen nzIcon="user" nzTitle="subnav 1">
          <ul>
            <li nz-menu-item>option1</li>
            <li nz-menu-item>option2</li>
            <li nz-menu-item>option3</li>
            <li nz-menu-item>option4</li>
          </ul>
        </li>
        <li nz-submenu nzOpen nzIcon="user" nzTitle="subnav 1">
          <ul>
            <li nz-menu-item>option1</li>
            <li nz-menu-item>option2</li>
            <li nz-menu-item>option3</li>
            <li nz-menu-item>option4</li>
          </ul>
        </li>
        <li nz-submenu nzTitle="subnav 2" nzIcon="laptop">
          <ul>
            <li nz-menu-item>option5</li>
            <li nz-menu-item>option6</li>
            <li nz-menu-item>option7</li>
            <li nz-menu-item>option8</li>
          </ul>
        </li>
        <li nz-submenu nzTitle="subnav 3" nzIcon="notification">
          <ul>
            <li nz-menu-item>option9</li>
            <li nz-menu-item>option10</li>
            <li nz-menu-item>option11</li>
            <li nz-menu-item>option12</li>
          </ul>
        </li>
      </ul>
    </nz-sider>
    <nz-layout class="inner-layout">
      <nz-content style="background-color: #f4f8fd;">
        <p *ngFor="let item of listContent">{{ item }}</p>
      </nz-content>
    </nz-layout>
  </nz-layout>
</nz-layout> -->

<!-- ------------------------------------------------------------------------------------------------------------------------ -->

<div style="height: 100%;">

    <nz-layout style="background-color: #f6f7f9;">
        <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" class="st-sider"
            style="background-color: white;">
            <div class="logo" style="position: relative;">
                <img *ngIf="!isCollapsed" style="position: absolute; top: 20px; left: 20px;"
                  src="/assets/img/logo.png"
                    [ngStyle]="{ width: '155px' }" />
                <img *ngIf="isCollapsed"
                    src="/assets/img/logo.png"
                    width="55px" style="margin-left: 12px; margin-top: 20px;">
            </div>
            <ul nz-menu nzMode="inline" class="sider-menu" style="background-color: white;" nzTheme="light">
                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/dashboard" [nzSelected]="mainPath==='dashboard'">
          <i nz-icon nzType="line-chart"></i>
          <span>Dashboard</span>
        </li> -->

                <!-- <li nz-submenu nzTitle="Course" nzIcon="book" [nzOpen]="mainPath==='courses'" *ngIf="(levelPermissionFeature.indexOf('/courses/main') > -1)||(levelPermissionFeature.indexOf('/courses/special') > -1)">
          <ul>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/courses/main') > -1" routerLink="/courses/main" [nzSelected]="subPath==='courses/main'"><i nz-icon nzType="read" nzTheme="outline"></i>อาณาจักร</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/courses/special') > -1"routerLink="/courses/special" [nzSelected]="subPath==='courses/special'"><i nz-icon nzType="star" nzTheme="outline"></i>Special Course</li>
          </ul>
        </li> -->
                <!-- <li nz-submenu nzTitle="Class" nzIcon="read" [nzOpen]="mainPath==='class'" *ngIf="(levelPermissionFeature.indexOf('/class/main') > -1)||(levelPermissionFeature.indexOf('/class/special') > -1)||(levelPermissionFeature.indexOf('/class/close') > -1)">
          <ul>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/class/main') > -1" routerLink="/class/main" [nzSelected]="subPath==='class/main'"><i nz-icon nzType="read" nzTheme="outline"></i>ห้องเรียน</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/class/special') > -1" routerLink="/class/special" [nzSelected]="subPath==='class/special'"><i nz-icon nzType="star" nzTheme="outline"></i>Special Class</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/class/close') > -1" routerLink="/class/close" [nzSelected]="subPath==='class/close'"><i nz-icon nzType="check-circle" nzTheme="outline"></i>Approve Class</li>
          </ul>
        </li> -->

                <!-- <li nz-submenu nzTitle="Teacher" nzIcon="user" [nzOpen]="mainPath==='teacher'" *ngIf="levelPermissionFeature.indexOf('/teacher') > -1">
          <ul>
            <li nz-menu-item routerLink="/teacher" [nzSelected]="subPath==='teacher'"><i nz-icon nzType="team" nzTheme="outline"></i>All teacher</li>
            <li nz-menu-item routerLink="/teacher/leave" [nzSelected]="subPath==='teacher/leave'"><i nz-icon nzType="close-circle" nzTheme="outline"></i>Leave</li>
          </ul>
        </li> -->

                <!-- <li nz-submenu nzTitle="Customers" nzIcon="team" [nzOpen]="mainPath==='customers'" *ngIf="(levelPermissionFeature.indexOf('/customers/new') > -1)||(levelPermissionFeature.indexOf('/customers/student') > -1)||(levelPermissionFeature.indexOf('/customers/parent') > -1)">
          <ul>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/customers/new') > -1" routerLink="/customers/new" [nzSelected]="subPath==='customers/new'"><i nz-icon nzType="login" nzTheme="outline"></i>New Students</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/customers/student') > -1" routerLink="/customers/student" [nzSelected]="subPath==='customers/student'"><i nz-icon nzType="smile" nzTheme="outline"></i>Students</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/customers/parent') > -1" routerLink="/customers/parent" [nzSelected]="subPath==='customers/parent'"><i nz-icon nzType="user" nzTheme="outline"></i>Parent</li>
          </ul>
        </li> -->
                <!-- <li nz-submenu nzTitle="Finance" nzIcon="dollar" [nzOpen]="mainPath==='finance'" *ngIf="(levelPermissionFeature.indexOf('/finance/recurring') > -1)||(levelPermissionFeature.indexOf('/finance/contract') > -1)||(levelPermissionFeature.indexOf('/finance/notification') > -1)||(levelPermissionFeature.indexOf('/finance/export') > -1)||(levelPermissionFeature.indexOf('/finance/report') > -1)||(levelPermissionFeature.indexOf('finance/manual') > -1)||(levelPermissionFeature.indexOf('/finance/discount') > -1)">
          <ul>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('') > -1" routerLink="/finance/recurring" [nzSelected]="subPath==='finance/recurring'"><i nz-icon nzType="file-text" nzTheme="outline"></i>Recurring Form</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('') > -1" routerLink="/finance/contract" [nzSelected]="subPath==='finance/contract'"><i nz-icon nzType="file-search" nzTheme="outline"></i>Contract</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('') > -1" routerLink="/finance/notification" [nzSelected]="subPath==='finance/notification'"><i nz-icon nzType="bell" nzTheme="outline"></i>Notifications</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('') > -1" routerLink="/finance/export" [nzSelected]="subPath==='finance/export'"><i nz-icon nzType="audit" nzTheme="outline"></i>Export (Excel)</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('') > -1" routerLink="/finance/report" [nzSelected]="subPath==='finance/report'"><i nz-icon nzType="file-protect" nzTheme="outline"></i>Bank Report</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('') > -1" routerLink="/finance/manual" [nzSelected]="subPath==='finance/manual'"><i nz-icon nzType="form" nzTheme="outline"></i>Manual</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('') > -1" routerLink="/finance/discount" [nzSelected]="subPath==='finance/discount'"><i nz-icon nzType="star" nzTheme="outline"></i>Discount</li>
          </ul>
        </li> -->
                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/teacher" [nzSelected]="mainPath==='teacher'">
          <i nz-icon nzType="read"></i>
          <span>Teachers</span>
        </li> -->


                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/exam" [nzSelected]="mainPath==='exam'">
          <i nz-icon nzType="file-done"></i>
          <span>Exam</span>
        </li> -->

                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/attendant" [nzSelected]="mainPath==='attendant'" *ngIf="levelPermissionFeature.indexOf('/attendant') > -1">
          <i nz-icon nzType="clock-circle"></i>
          <span>Attendance</span>
        </li> -->

                <!-- <li nz-submenu nzTitle="Broadcast" nzIcon="notification" [nzOpen]="mainPath==='broadcast'" *ngIf="(levelPermissionFeature.indexOf('/broadcast/creation') > -1)||(levelPermissionFeature.indexOf('/broadcast/schedule') > -1)||(levelPermissionFeature.indexOf('/broadcast/history') > -1)">
          <ul>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/broadcast/creation') > -1" routerLink="/broadcast/creation" [nzSelected]="subPath==='broadcast/creation'"><i nz-icon nzType="form" nzTheme="outline"></i>Create</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/broadcast/schedule') > -1" routerLink="/broadcast/schedule" [nzSelected]="subPath==='broadcast/schedule'"><i nz-icon nzType="calendar" nzTheme="outline"></i>Schedule</li>
            <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/broadcast/history') > -1" routerLink="/broadcast/history" [nzSelected]="subPath==='broadcast/history'"><i nz-icon nzType="clock-circle" nzTheme="outline"></i>History</li>
          </ul>
        </li> -->


                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/calendar" [nzSelected]="mainPath==='calendar'" *ngIf="levelPermissionFeature.indexOf('/calendar') > -1">
          <i nz-icon nzType="calendar"></i>
          <span>Calendar</span>
        </li> -->

                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/user" [nzSelected]="mainPath==='user'" *ngIf="levelPermissionFeature.indexOf('/user') > -1">
          <i nz-icon nzType="setting"></i>
          <span>User Management</span>
        </li> -->
                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/dashboard"
                    [nzSelected]="mainPath==='dashboard'">
                    <i nz-icon nzType="bar-chart" nzTheme="outline"></i><span>แดชบอร์ด</span>
                </li> -->
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/courses/main"
                    [nzSelected]="subPath==='courses/main'">
                    <i nz-icon nzType="bank"></i><span>อาณาจักร</span>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/class/main"
                    [nzSelected]="subPath==='class/main'">
                    <i nz-icon nzType="book"></i><span>คอร์สเรียน</span>
                </li>
                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/teacher"
                    [nzSelected]="mainPath==='teacher'">
                    <i nz-icon nzType="user"></i><span>โค้ช</span>
                </li> -->
                <li nz-submenu nzTitle="โค้ช" nzIcon="user" [nzOpen]="mainPath==='teacher'" *ngIf="levelPermissionFeature.indexOf('/teacher') > -1">
                  <ul>
                    <li nz-menu-item routerLink="/teacher" [nzSelected]="subPath==='teacher'"><i nz-icon nzType="menu" nzTheme="outline"></i>รายชื่อโค้ช</li>
                    <li nz-menu-item routerLink="/teacher/leave" [nzSelected]="subPath==='teacher/leave'"><i nz-icon nzType="form" nzTheme="outline"></i>อนุมัติการลา</li>
                  </ul>
                </li>
                <li nz-submenu nzTitle="บุคคลภายนอก" nzIcon="team" [nzOpen]="mainPath==='customers'"
                    *ngIf="(levelPermissionFeature.indexOf('/customers/new') > -1)||(levelPermissionFeature.indexOf('/customers/student') > -1)||(levelPermissionFeature.indexOf('/customers/parent') > -1)">
                    <ul>
                        <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/customers/new') > -1"
                            routerLink="/customers/new" [nzSelected]="subPath==='customers/new'"><i nz-icon
                                nzType="login" nzTheme="outline"></i>สมัครเรียน</li>
                        <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/customers/student') > -1"
                            routerLink="/customers/student" [nzSelected]="subPath==='customers/student'"><i nz-icon
                                nzType="team" nzTheme="outline"></i>นักเรียน</li>
                        <li nz-menu-item *ngIf="levelPermissionFeature.indexOf('/customers/parent') > -1"
                            routerLink="/customers/parent" [nzSelected]="subPath==='customers/parent'"><i nz-icon
                                nzType="team" nzTheme="outline"></i>ผู้ปกครอง</li>
                    </ul>
                </li>
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/attendant"
                    [nzSelected]="mainPath==='attendant'" *ngIf="levelPermissionFeature.indexOf('/attendant') > -1">
                    <i nz-icon nzType="clock-circle"></i><span>คลาสเรียนวันนี้</span>
                </li>
                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/payment" [nzSelected]="mainPath==='payment'" >
                  <i nz-icon nzType="dollar-circle" nzTheme="outline"></i>
                  <span>การเงิน</span>
                </li> -->
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" routerLink="/user" [nzSelected]="mainPath==='user'" *ngIf="levelPermissionFeature.indexOf('/user') > -1">
                  <i nz-icon nzType="setting"></i>
                  <span>จัดการบุคคล</span>
                </li>
            </ul>

        </nz-sider>
        <nz-layout>
            <nz-header nz-row [ngClass]="isCollapsed ? 'st-header-collap': ''">
                <div nz-col nzSpan="12">
                    <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                        (click)="isCollapsedChange()"></i>
                </div>
                <!-- <div nz-col nzSpan="12" style="text-align: right; padding-right: 25px; margin-top: -2px;">
                  <span (click)="logout()" style=" margin-right: 10px; font-size: 12px; color: rgba(0,0,0,0.35);">Logout</span> 
                   <nz-avatar [nzSize]="30" nzIcon="user" nzSrc="">
                  </nz-avatar>
                  
                  <button nz-button (click)="logout()" nzType="primary" nzDanger><i nz-icon nzType="logout" nzTheme="outline"></i>Logut</button>
                </div> -->
            </nz-header>
            <nz-content [ngClass]="isCollapsed ? 'st-content-collap': ''">
                <div class="inner-content">
                    <router-outlet></router-outlet>
                </div>
            </nz-content>
            <nz-footer>
                2020 INET Digital Outsource (KaaS)
            </nz-footer>
        </nz-layout>
    </nz-layout>

</div>