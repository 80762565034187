import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'specialcourse'
})
export class SpecialcoursePipe implements PipeTransform {

  transform = (uid: string): string => {
    return `/api/v1/course/special/img/${uid}`;
  }

}
