import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';


@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {

  constructor(
    private auth: AuthService,
    private router: Router,
    private modalService: NzModalService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    const module: string = url.split('/')[1];

    // // backtodo delete
    // const listFeature = [
    //   { checked: false, name: 'คอร์สเรียนหลัก (Main course)', path: '/courses/main', group: 'course' },
    //   { checked: false, name: 'คอร์สเรียนพิเศษ (Special course)', path: '/courses/special', group: 'course' },
    //   { checked: false, name: 'คลาสเรียนหลัก (Main class)', path: '/class/main', group: 'course' },
    //   { checked: false, name: 'คลาสเรียนพิเศษ (Special class)', path: '/class/special', group: 'class' },
    //   { checked: false, name: 'อนุมัติคลาสเรียน (Approvement)', path: '/class/close', group: 'class' },
    //   { checked: false, name: 'ข้อมูลโค้ช และ อนุมัติการลา', path: '/teacher', group: 'teacher' },
    //   { checked: false, name: 'จัดการผู้สมัครเรียน', path: '/customers/new', group: 'customers' },
    //   { checked: false, name: 'ข้อมูลนักเรียน', path: '/customers/student', group: 'customers' },
    //   { checked: false, name: 'ข้อมูลผู้ปกครอง', path: '/customers/parent', group: 'customers' },
    //   { checked: false, name: 'Recurring Form', path: '/finance/recurring', group: 'finance' },
    //   { checked: false, name: 'การต่อสัญญา (แจ้งเตือน)', path: '/finance/contract', group: 'finance' },
    //   { checked: false, name: 'การต่อสัญญา (ติดตามสถานะ)', path: '/finance/notification', group: 'finance' },
    //   { checked: false, name: 'การต่อสัญญา (Export Excel)', path: '/finance/export', group: 'finance' },
    //   { checked: false, name: 'การต่อสัญญา (Bank report)', path: '/finance/report', group: 'finance' },
    //   { checked: false, name: 'การเพิ่มเงินเข้า-ออก wallet', path: '/finance/manual', group: 'finance' },
    //   { checked: false, name: 'ส่วนลดพิเศษของนักเรียน (Promotion discount)', path: '/finance/discount', group: 'finance' },
    //   { checked: false, name: 'การเช็คชื่อเข้าเรียนของนักเรียน', path: '/attendant', group: 'attendant' },
    //   { checked: false, name: 'Broadcast (สร้าง)', path: '/broadcast/creation', group: 'attendant' },
    //   { checked: false, name: 'Broadcast (ตารางล่วงหน้า)', path: '/broadcast/schedule', group: 'attendant' },
    //   { checked: false, name: 'Broadcast (ประวัติ)', path: '/broadcast/history', group: 'attendant' },
    //   { checked: false, name: 'จัดการวันหยุด', path: '/calendar', group: 'calendar' },
    //   { checked: false, name: 'จัดการผู้ใช้งาน', path: '/user', group: 'user' },
    //   { checked: false, name: 'Dashboard (แดชบอร์ด)', path: '/dashboard', group: 'dashboard' }
    // ];

    // let listFeatureString = [];
    // listFeature.forEach((v) => {
    //   listFeatureString.push(v.path);
    // });

    // localStorage.setItem('feature', JSON.stringify(listFeatureString));
    // localStorage.setItem('permission', 'admin');


    if (this.auth.isPermission() === null) {
      this.router.navigate(['/auth']);
      return false;
    } else {
      if (this.auth.isPermission() === 'admin') {

        //level
        if (localStorage.getItem('feature')) {
          const levelPermissionFeature = JSON.parse(localStorage.getItem('feature'));
          let check = false;
          levelPermissionFeature.forEach((v, k: number) => {
            if (url.startsWith(v)) {
              check = true
            }
          });
          if (check) {
            return true;
          } else {
            this.router.navigate([levelPermissionFeature[0]]);
            return false;
          }

        } else {
          this.modalService.error({
            nzTitle: 'ผิดพลาด',
            nzContent: `Account ของคุณไม่ได้รับอนุญาตให้เข้าใช้งานระบบนี้ กรุณาติดต่อเจ้าหน้าที่`,
          });
          return false;
        }
      } else {
        this.modalService.error({
          nzTitle: 'ผิดพลาด',
          nzContent: `Account ของคุณไม่ได้รับอนุญาตให้เข้าใช้งานระบบนี้ กรุณาติดต่อเจ้าหน้าที่`,
        });
        return false;
      }
    }

  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

}
