import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/shared/services/api.service';
import { ApiResult } from 'src/app/shared/models/http.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class TeacherService {

  private prefixUrl = '/v1/teacher';

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { }

  private uri = (uri: string = ''): string => `${this.prefixUrl}${uri}`;

  getListTeacher = async (page: number = 1, size: number = 10, search: string = ''): Promise<ApiResult> => {
    const params = new HttpParams().append('page', `${page}`).append('size', `${size}`).append('search', `${search}`);
    return this.api.get(this.uri(`/profile`), params).toPromise();
  }

  getListTeacherDetail = async (page: number = 1, size: number = 10, search: string = ''): Promise<ApiResult> => {
    const params = new HttpParams().append('page', `${page}`).append('size', `${size}`).append('search', `${search}`);
    return this.api.get(this.uri(`/profile/all/detail`), params).toPromise();
  }

  getTeacherDetail = async (oneId: string): Promise<ApiResult> => {
    return this.api.get(this.uri(`/profile/${oneId}/detail`)).toPromise();
  }

  getTeacherImage = async (oneId: string): Promise<ApiResult> => {
    return this.api.get(this.uri(`/profile/${oneId}/img`)).toPromise();
  }


  // Leave
  getLeaveRequest = async (): Promise<ApiResult> => {
    return this.api.get(this.uri(`/leave/request`)).toPromise();
  }

  updateLeaveStatus = async (body: object): Promise<ApiResult> =>
    this.api.post(this.uri('/leave/request'), body).toPromise()

  // Leave delete
  getLeaveRequestDelete = async (): Promise<ApiResult> => {
    return this.api.get(this.uri(`/leave/request/cancel`)).toPromise();
  }

  updateLeaveStatusDelete = async (body: object): Promise<ApiResult> =>
    this.api.post(this.uri('/leave/request/cancel'), body).toPromise()

  // record
  getLeaveRecordByAccountId = async (oneId: string, page: number = 1, size: number = 10, start: string, end: string): Promise<ApiResult> => {
    const params = new HttpParams().append('page', `${page}`).append('size', `${size}`).append('start_date', `${start}`).append('end_date', `${end}`);
    return this.api.get(this.uri(`/record/leave/${oneId}`), params).toPromise();
  }

  getTeachingRecordByAccountId = async (oneId: string, page: number = 1, size: number = 10, start: string, end: string): Promise<ApiResult> => {
    const params = new HttpParams().append('page', `${page}`).append('size', `${size}`).append('start_date', `${start}`).append('end_date', `${end}`);
    return this.api.get(this.uri(`/record/teaching/${oneId}`), params).toPromise();
  }

  // schedule
  getScheduleByAccountId = async (oneId: string): Promise<ApiResult> => {
    return this.api.get(this.uri(`/schedule/${oneId}`)).toPromise();
  }

  // schedule
  getScheduleCalendarByAccountId = async (oneId: string, monthDate: number): Promise<ApiResult> => {
    return this.api.get(this.uri(`/schedule/${oneId}/calendar?month=${monthDate}`)).toPromise();
  }
}
