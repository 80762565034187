import { Pipe, PipeTransform } from '@angular/core';
import { TeacherService } from '../../features/teacher/_services/teacher.service';

@Pipe({
  name: 'teacher'
})
export class TeacherPipe implements PipeTransform {

  constructor(
    private api: TeacherService,
  ) { }

  transform = (accountId: string): any => {
    this.api.getTeacherImage(accountId).then((r) => {
      if (r) {
        return r.toString();
      }
    });
  }

}
