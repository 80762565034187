import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from 'src/app/core/layouts/main-layout/main-layout.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { AdminGuard } from 'src/app/core/guards/admin.guard';

// localStorage.setItem('feature', JSON.stringify(['/courses/special', '/class/main']));
let redirectUrl = 'courses';
if (localStorage.getItem('feature')) {
  const feature = JSON.parse(localStorage.getItem('feature')) || [];
  if (feature.length > 0) {
    redirectUrl = feature[0];
  }
}

const routes: Routes = [
  { path: '', redirectTo: 'courses', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
    component: MainLayoutComponent,
  },
  {
    path: 'courses',
    loadChildren: () =>
      import('./features/courses/courses.module').then(m => m.CoursesModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'class',
    loadChildren: () => import('./features/schedule/schedule.module').then(m => m.ScheduleModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'teacher',
    loadChildren: () => import('./features/teacher/teacher.module').then(m => m.TeacherModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'customers',
    loadChildren: () => import('./features/customers/customers.module').then(m => m.CustomersModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'finance',
    loadChildren: () => import('./features/notification/notification.module').then(m => m.NotificationModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'broadcast',
    loadChildren: () => import('./features/broardcast/broardcast.module').then(m => m.BroardcastModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'attendant',
    loadChildren: () => import('./features/attendant/attendant.module').then(m => m.AttendantModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./features/calendar/calendar.module').then(m => m.CalendarModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./features/user-management/user-management.module').then(m => m.UserManagementModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'payment',
    loadChildren: () => import('./features/payment/payment.module').then(m => m.PaymentModule),
    component: MainLayoutComponent,
  },
  // {
  //   path: 'special',
  //   loadChildren: () => import('./features/special/special.module').then(m => m.SpecialModule),
  //   component: MainLayoutComponent,
  //   canActivate:[AuthGuard, AdminGuard]
  //   canActivate: [AdminGuard, AuthGuard]
  // },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
  //   component: MainLayoutComponent,
  //   canActivate: [AdminGuard, AuthGuard]
  // },
  // {
  //   path: 'exam', loadChildren: () => import('./features/exam/exam.module').then(m => m.ExamModule),
  //   component: MainLayoutComponent,
  //   canActivate: [AdminGuard, AuthGuard]
  // },
  // { path: 'error', component: ErrorComponent, data: Err404 },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
