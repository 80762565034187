import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/shared/services/api.service';
import { ApiResult } from 'src/app/shared/models/http.model';
import { generateSalt } from '../operators/util.operator';
import { tap } from 'rxjs/operators';
import { StaffView, Account } from '../models/api.model';
import { LoginTicket, DataVerify } from '../models/auth.model';
import { HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';



@Injectable()
export class AuthService {

  private redirectUrl = '';
  private profile: Account;
  private organizes: Array<StaffView>;
  constructor(private api: ApiService, private http: HttpClient) { }

  private encryptSecret = (text: string, key: string): string =>
    CryptoJS.AES.encrypt(text, key).toString()

  isLevel = (): string => localStorage.getItem('level');

  isPermission = (): string => localStorage.getItem('permission');

  setRedirectUrl = (url: string): void => {
    this.redirectUrl = url;
  }

  getListCompanyAccount = async (): Promise<ApiResult> =>
    this.api.get(`/v1/list/company`).toPromise()

  getListCustomerCompany = async (company: string): Promise<ApiResult> =>
    this.api.get(`/v1/list/customer?company=${company}`).toPromise()

  getSignInUrl = async (): Promise<string> => {
    return this.api
      .get(`/v1/auth/login`).toPromise()
      .then((r) => r.data as string);
  }

  verifySSOAuthorizedCode = async (code: string, reCaptChaToken: string): Promise<ApiResult> => {
    const salt = generateSalt(32);
    const params = new HttpParams().append('salt', `${salt}`);
    const r = await this.api
      .get(`/v1/auth/ticket`, params)
      .toPromise()
      .then((result) => result.data);
    const secret = this.encryptSecret(code, salt);
    const data = {
      secret,
      ticket: (r as LoginTicket).ticket,
      token: reCaptChaToken,
    } as object;
    return this.api
      .post(`/v1/auth/login`, data, params)
      .pipe(
        tap(
          (result) => {
            this.setLocalStoragePermission(result.data);
          },
          (result) => {
            if (result.status === 403) {
              // this.setLocalStorage(result.error.data, result.error.error);
            } else {
              localStorage.clear();
            }
          }
        )
      )
      .toPromise();
  }

  logout = async (): Promise<boolean> => {
    return this.api
      .delete(`/v1/auth/logout`, null)
      .pipe(
        tap(() => {
          this.profile = null;
          this.isLevel = null;
          this.redirectUrl = '';
          localStorage.clear();
        })
      )
      .toPromise()
      .then(() => true)
      .catch(() => false);
  }

  isAuthorizedAccess = async (): Promise<boolean> => {
    return this.api
      .get('/v1/auth')
      .toPromise()
      .then(() => true)
      .catch((err) => {
        localStorage.clear();
        return false;
      });
  }

  // getMyProfile = async (): Promise<Account> => {
  //   if (this.profile) {
  //     return new Promise<Account>((resolve, reject) => {
  //       return resolve(this.profile);
  //     });
  //   }
  //   return this.api
  //     .get('/v1/profile')
  //     .pipe(
  //       tap((r) => {
  //         this.profile = r.data as Account;
  //       })
  //     )
  //     .toPromise()
  //     .then((r) => r.data as Account);
  // }

  // getMyOrganizes = async (): Promise<Array<StaffView>> => {
  //   if (this.organizes) {
  //     return new Promise<Array<StaffView>>((resolve, reject) => {
  //       return resolve(this.organizes);
  //     });
  //   }
  //   return this.api
  //     .get('/v1/profile/org')
  //     .pipe(
  //       tap((r) => {
  //         this.organizes = r.data as Array<StaffView>;
  //       })
  //     )
  //     .toPromise()
  //     .then((r) => r.data as Array<StaffView>);
  // }

  setLocalStorage = (data: DataVerify, status: string): void => {
    localStorage.setItem('resource_key', data.resource_key);
    localStorage.setItem('token', data.token);
    localStorage.setItem('type', data.type);
    if (status === 'valid account') {
      localStorage.setItem('status', 'valid');
    } else if (status === 'waiting account') {
      localStorage.setItem('status', 'waiting');
    } else if (status === 'suspended account') {
      localStorage.setItem('status', 'suspended');
    } else if (status === 'activated') {
      localStorage.setItem('status', 'activated');
    }
  }

  setLocalStoragePermission = (data: DataVerify): void => {
    localStorage.setItem('resource_key', data.resource_key);
    localStorage.setItem('token', data.token);
    localStorage.setItem('type', data.type);
    localStorage.setItem('permission', data.permission);
    localStorage.setItem('feature', JSON.stringify(data.feature));
    localStorage.setItem('featureName', data.feature_name);
    localStorage.setItem('account_id', data.account_id);
  }

}
